import { BackgroundScene } from './scenes/BackgroundScene.js'

if (document.getElementById('rendererContainer')) {
  const loadMainScene = async () => {
    const scene = new BackgroundScene
    await scene.init({
      canvas: document.getElementById('rendererContainer'),
      initial: {
        targetName: document.body.dataset.backgroundModel,
        scrollable: document.body.dataset.backgroundScrollable === '',
      },
    })
    scene.run()

    return scene
  }

  loadMainScene()
    .then((scene) => {
      window.BackgroundScene = scene
    })
    .catch(err => {
      console.error(err)
    })
}
