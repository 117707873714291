import { DRACOLoader } from 'three/addons/loaders/DRACOLoader'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { HalftonePass } from '../three/HalftonePass.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import * as THREE from 'three'
import gsap from 'gsap'
import shuffle from 'lodash-es/_arrayShuffle'

// Models
import BackgroundModels from '../../models/BackgroundModels.gltf'

// Textures
// Source: https://github.com/nidorx/matcaps/blob/master/PAGE-16.md#771a1f_d2939e_b6595d_9d4b54
import RedMatCap from '../../textures/red_matcap_64px.png'

// Shaders
import CellShellVertexShader from '../../shaders/CellShell.vert'
import CellShellFragmentShader from '../../shaders/CellShell.frag'

const randomNumber = (min, max) => ((max - min) * Math.random()) + min
const between = (value, min, max) => value >= min && value < max

const cameraTargets = {
    helix:      new THREE.Vector3(0, 0, 6),
    cells:      new THREE.Vector3(0, -5.5, 6),
    bloodCells: new THREE.Vector3(0, -13, 6),
    viruses:    new THREE.Vector3(0, -20, 6),
    _blank:     new THREE.Vector3(0, -26, 6),
}

const transforms = {
  small:  {
    helix:      {
      position: new THREE.Vector3(0, 0, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    cells:      {
      position: new THREE.Vector3(0, -6, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    bloodCells: {
      position: new THREE.Vector3(0, -14, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    viruses:    {
      position: new THREE.Vector3(0, -20, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
  },
  medium: {
    helix:      {
      position: new THREE.Vector3(1.5, 0, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    cells:      {
      position: new THREE.Vector3(0, -6, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    bloodCells: {
      position: new THREE.Vector3(2, -14, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    viruses:    {
      position: new THREE.Vector3(0, -20, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
  },
  large:  {
    helix:      {
      position: new THREE.Vector3(3, 0, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    cells:      {
      position: new THREE.Vector3(0, -6, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    bloodCells: {
      position: new THREE.Vector3(3.5, -14, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
    viruses:    {
      position: new THREE.Vector3(0, -20, 0),
      scale:    new THREE.Vector3(1., 1., 1.),
    },
  },
}

export class BackgroundScene {

  get transforms() {
    return transforms[this.breakpoint]
  }

  async init({canvas, initial}) {
    this.time = 0
    this.times = []
    this.fps = 0
    this.scrollProgress = 0
    this.mouse = { x: 0, y: 0 }
    this.breakpoint = this.getBreakpoint()
    this.targets = cameraTargets

    this.scene = new THREE.Scene()
    this.clock = new THREE.Clock()
    this.gltfLoader = new GLTFLoader()
    const dracoLoader = new DRACOLoader
    dracoLoader.setDecoderPath('/draco/')
    this.gltfLoader.setDRACOLoader(dracoLoader)
    this.textureLoader = new THREE.TextureLoader()

    this.target = (initial.targetName) ? this.targets[initial.targetName] : this.targets.helix
    this.isScrollable = (initial.scrollable) ? initial.scrollable : false

    // Renderer
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: false,
    })

    this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)

    // Setup scene
    this.settings = {
      speed: 0.05,
    }

    await this.setupScene()

    if (window.Kirby.debug) {
      console.log('add debugging tools')

      const [ Tweakpane, Essentials ] = await Promise.all([
        import('tweakpane'),
        import('@tweakpane/plugin-essentials'),
      ])

      this.addGUI(Tweakpane.Pane, Essentials)
    }

    this.addEventListener()

    this.scrollProgress = this.getNormalizedScrollProgress()

    // Post-Processing
    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(new RenderPass(this.scene, this.camera))

    const params = {
      shape: 1,
      radius: 6 * Math.floor(window.devicePixelRatio),
      rotateR: Math.PI * 0.25,
      scatter: 0,
      blending: 1,
      blendingMode: 1,
      greyscale: false,
      disable: false,
    }

    this.composer.addPass(new HalftonePass(window.innerWidth, window.innerHeight, params))

    return this
  }

  setScrollable(value) {
    this.isScrollable = value
  }

  lookAt(targetName) {
    if (this.targets[targetName] === undefined) {
      console.info(`${targetName} is not a valid target. Must be one of ${Object.keys(this.targets).join(',')}`)
      return
    }

    this.target = this.targets[targetName]
  }

  run() {
    const delta = this.clock.getDelta()
    this.time += delta * this.settings.speed * 0.5

    if (this.fpsGraph) {
      this.fpsGraph.begin()
    }

    this.update()

    this.composer.render(delta)

    window.requestAnimationFrame((function () {
      const now = performance.now()
      while (this.times.length > 0 && this.times[0] <= now - 10000) {
        this.times.shift()
      }
      this.times.push(now)
      if (now > 10000) {
        this.fps = this.times.length / 10
      }
    }).bind(this))

    if (this.fpsGraph) {
      this.fpsGraph.end()
    }

    return window.requestAnimationFrame(this.run.bind(this))
  }

  update() {
    this.scrollProgress = this.getNormalizedScrollProgress()

    const randomize = (mesh, index, rSpeed) => {
      mesh.position.y = Math.sin(((this.time * 10) + (index * 1.5)) / 4)
      mesh.rotation.x = Math.sin((this.time * 10) + (index * 1.5)) * rSpeed
      mesh.rotation.y = Math.cos((this.time * 10) + (index * 1.5)) * rSpeed
      mesh.rotation.z = Math.sin((this.time * 10) + (index * 1.5)) * rSpeed
    }

    this.meshes.helix.children.forEach((mesh, index) => randomize(mesh, index, 0.25))
    this.meshes.cells.children.forEach((mesh, index) => randomize(mesh, index, 1))
    this.meshes.bloodCells.children.forEach((mesh, index) => randomize(mesh, index, 1))
    this.meshes.viruses.children.forEach((mesh, index) => randomize(mesh, index, 1))

    const animationSettings = {
      duration: 0.334,
      ease: 'power1.inOut',
    }

    gsap.to(this.meshes.bloodCells.rotation, {
      y: this.mouse.x * 0.15,
      x: this.mouse.y * -0.15,
      ...animationSettings,
    })

    if (this.meshes.bloodCells.position.distanceTo(this.transforms.bloodCells.position) > 0.025) {
      gsap.to(this.meshes.bloodCells.position, {
        x: this.transforms.bloodCells.position.x,
        y: this.transforms.bloodCells.position.y,
        z: this.transforms.bloodCells.position.z,
        ...animationSettings,
      })
    }

    gsap.to(this.meshes.cells.rotation, {
      y: this.mouse.x * 0.1,
      x: this.mouse.y * -0.1,
      ...animationSettings,
    })

    if (this.meshes.cells.position.distanceTo(this.transforms.cells.position) > 0.025) {
      gsap.to(this.meshes.cells.position, {
        x: this.transforms.cells.position.x,
        y: this.transforms.cells.position.y,
        z: this.transforms.cells.position.z,
        ...animationSettings,
      })
    }

    gsap.to(this.meshes.helix.rotation, {
      y: this.mouse.x * 0.1,
      x: this.mouse.y * -0.1,
      ...animationSettings,
    })

    if (this.meshes.helix.position.distanceTo(this.transforms.helix.position) > 0.025) {
      gsap.to(this.meshes.helix.position, {
        x: this.transforms.helix.position.x,
        y: this.transforms.helix.position.y,
        z: this.transforms.helix.position.z,
        ...animationSettings,
      })
    }

    gsap.to(this.meshes.viruses.rotation, {
      y: this.mouse.x * 0.15,
      x: this.mouse.y * -0.15,
      ...animationSettings,
    })

    if (this.meshes.viruses.position.distanceTo(this.transforms.viruses.position) > 0.025) {
      gsap.to(this.meshes.viruses.position, {
        x: this.transforms.viruses.position.x,
        y: this.transforms.viruses.position.y,
        z: this.transforms.viruses.position.z,
        ...animationSettings,
      })
    }

    if (this.isScrollable) {
      switch (true) {
        case between(this.scrollProgress, 0, 0.25):
          this.target = this.targets.helix
          break
        case between(this.scrollProgress, 0.25, 0.5):
          this.target = this.targets.cells
          break
        case between(this.scrollProgress, 0.5, 0.75):
          this.target = this.targets.bloodCells
          break
        case between(this.scrollProgress, 0.75, 1.01):
          this.target = this.targets.viruses
          break
      }
    }

    if (this.camera.position.x !== this.target.x || this.camera.position.y !== this.target.y || this.camera.position.z !== this.target.z) {
      gsap.to(this.camera.position, {
        x: this.target.x,
        y: this.target.y,
        z: this.target.z,
        ...animationSettings,
      })
    }
  }

  loadMeshes() {
    const meshes = {
      bloodCells: new THREE.Group(),
      cells:      new THREE.Group(),
      helix:      new THREE.Group(),
      viruses:    new THREE.Group(),
    }

    return new Promise((resolve, reject) => {
      this.gltfLoader.load(BackgroundModels,
        (gltf) => {
          const children = gltf.scene.children.filter(child => child.type === 'Mesh')

          children.forEach(mesh => {
            if (mesh.name.startsWith('Cell')) {
              meshes.cells.add(mesh)
            }

            if (mesh.name.startsWith('BloodCell')) {
              meshes.bloodCells.add(mesh)
            }

            if (mesh.name.startsWith('Helix')) {
              meshes.helix.add(mesh)
            }

            if (mesh.name.startsWith('Virus')) {
              meshes.viruses.add(mesh)
            }
          })

          resolve(meshes)
        },
        undefined,
        (err) => reject(err))
    })
  }

  loadTextures() {
    const textures = {}

    return new Promise((resolve, reject) => {
      this.textureLoader.load(
        RedMatCap,
        (texture) => {
          textures.redMatCap = texture
          resolve(textures)
        },
        undefined,
        (err) => reject(err)
      )
    })
  }

  async setupScene() {
    this.scene.background = new THREE.Color(0x050505)

    this.meshes = await this.loadMeshes()
    this.textures = await this.loadTextures()

    // Setup and position camera
    this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 25)
    this.camera.position.copy(this.target)

    // Setup and position blood cells
    this.meshes.bloodCells.children.forEach(mesh => {
      mesh.material = new THREE.MeshMatcapMaterial({ matcap: this.textures.redMatCap })
      const scaleFactor = randomNumber(0.7, 1.0)
      mesh.scale.set(scaleFactor, scaleFactor, scaleFactor)
    })
    this.meshes.bloodCells.position.copy(this.transforms.bloodCells.position)
    this.meshes.bloodCells.children = shuffle(this.meshes.bloodCells.children)
    this.scene.add(this.meshes.bloodCells)

    // Setup and position cells
    this.meshes.cells.children.forEach(mesh => {
      // Set material for cell shell
      mesh.material = new THREE.ShaderMaterial({
        vertexShader:   CellShellVertexShader,
        fragmentShader: CellShellFragmentShader,
        side:           THREE.FrontSide,
        transparent:    true,
      })
      // Set material for cell core
      mesh.children[0].material = new THREE.MeshMatcapMaterial({ matcap: this.textures.redMatCap })
      const scaleFactor = randomNumber(0.5, 0.8)
      mesh.scale.set(scaleFactor, scaleFactor, scaleFactor)
    })
    this.meshes.cells.position.copy(this.transforms.cells.position)
    this.meshes.cells.children = shuffle(this.meshes.cells.children)
    this.scene.add(this.meshes.cells)

    // Setup helix
    this.meshes.helix.children.forEach(mesh => {
      mesh.material = new THREE.MeshMatcapMaterial({ matcap: this.textures.redMatCap })
      mesh.position.z = 0
    })
    this.meshes.helix.position.copy(this.transforms.helix.position)
    this.scene.add(this.meshes.helix)

    // Setup Virus
    this.meshes.viruses.children.forEach(mesh => {
      mesh.material = new THREE.MeshMatcapMaterial({ matcap: this.textures.redMatCap })
      const scaleFactor = randomNumber(0.5, 0.8)
      mesh.scale.set(scaleFactor, scaleFactor, scaleFactor)
    })
    this.meshes.viruses.position.copy(this.transforms.viruses.position)
    this.meshes.viruses.children = shuffle(this.meshes.viruses.children)
    this.scene.add(this.meshes.viruses)
  }

  addEventListener() {
    window.addEventListener('resize', this.onWindowResize.bind(this))
    window.addEventListener('mousemove', this.onMouseMove.bind(this))
  }

  onWindowResize() {
    this.breakpoint = this.getBreakpoint()

    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.composer.setSize(window.innerWidth, window.innerHeight)
  }

  onMouseMove(ev) {
    this.mouse.x = (ev.clientX / window.innerWidth) * 2 - 1
    this.mouse.y = -(ev.clientY / window.innerHeight) * 2 + 1
  }

  getNormalizedScrollProgress() {
    return Math.min(1, window.scrollY / (document.body.getBoundingClientRect().height - window.innerHeight))
  }

  addGUI(Pane, EssentialsPlugin) {
    const style = document.createElement('style')
    style.setAttribute('data-tp-style', 'override')
    style.innerHTML = `.tp-dfwv { position: fixed !important; z-index: 100 }`
    document.getElementsByTagName('head')[0].appendChild(style)

    this.gui = new Pane({ title: 'Background scene' })
    this.gui.registerPlugin(EssentialsPlugin)

    this.fpsGraph = this.gui.addBlade({
      view:      'fpsgraph',
      label:     'FPS',
      lineCount: 2,
    })

    this.gui.addInput(this.settings, 'speed', {
      title: 'Speed',
      min:   0.01,
      max:   8,
      step:  0.01,
    })

    this.gui.expanded = false
  }

  getBreakpoint() {
    const breakpoints = [
      { name: 'large',  minWidth: 1536 },
      { name: 'medium', minWidth: 768 },
      { name: 'small',  minWidth: 0 },
    ]

    let match = 'small'

    for (let breakpoint of breakpoints) {
      if (window.matchMedia(`(min-width: ${breakpoint.minWidth}px)`).matches) {
        match = breakpoint.name
        break
      }
    }

    return match;
  }
}
